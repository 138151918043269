.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	min-height: 100vh;
	flex-direction: column;
	align-items: center;
	font-size: 16px;
	color: #000000;
	width: 100%;
	top: 0;
}

.app-link {
	cursor: pointer;
}

.form-signin {
	width: 100%;
	max-width: 300px;
	padding: 10px;
	margin: 0 auto;
	border: 1px solid white;
	border-radius: 10px;
	background: rgba(255, 255, 255, 0.7);
}

.form-signin .checkbox {
	font-weight: 400;
}

.form-signin .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	padding: 10px;
	font-size: 16px;
}

.form-signin .form-control:focus {
	z-index: 2;
}

.form-signin input[type="email"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

h1, h2, p, ul, li {
	font-family: sans-serif;
}

ul.header li {
	display: inline;
	list-style-type: none;
	margin: 0;
}

ul.header {
	background-image: linear-gradient(-180deg, #f3f3f3, #dee2e6);
	box-shadow: 0 .025rem .55rem rgba(0, 0, 0, .45) !important;
	padding: 0;
	height: 50px;
}

ul.header li a {
	color: #000000;
	font-weight: bold;
	text-decoration: none;
	padding: 15px;
	display: inline-block;
}

ul.header li span {
	font-weight: bold;
	padding: 15px;
	float: right !important;
}

ul.header li a.active {
	color: #FFFFFF;
	background-color: #17a2b8;
}

.topBlock {
	height: 50px;
}

fieldset {
	border: 1px solid #ced4da !important;
	padding: 1rem !important;
	border-radius: 5px;
}

legend {
	width: auto !important;
	margin-left: 1rem;
	padding-left: .5rem !important;
	padding-right: .5rem !important;
}

fieldset#certs {
	border: 1px solid black;
    margin: 20px;
    padding: 20px;
}

fieldset#certs legend {
	width: auto;
}

.content {
	background-color: #f5f5f5;
	margin-top: 1rem;
}

.content h2 {
	padding: 0;
	margin: 0;
}

.content li {
	margin-bottom: 10px;
}

.tableContent {
	margin-bottom: 3px;
	top: 0;
	position: relative;
	width: 1000px;
	height: 500px;
	overflow-y: scroll;
}

.menuButton {
	height: 64px;
	background: transparent;
	transition: 250ms ease all;
	border: none;
	color: #fff;
}

.styles_closeButton__20ID4 {
	position: absolute;
	top: 14px;
	right: 0;
	border: none;
	width: 35px;
	padding: 0;
	background-color: transparent;
	display: flex;
}

#testReultTable tr td {
	border: 1px solid black;
	text-align: center;
}

#test11Table td, th {
	padding: 5px;
	display: table-cell;
	text-align: left;
	vertical-align: middle;
	border-radius: 2px;
}

#header .col {
	padding: 0;
	float: left;
	box-sizing: border-box;
	min-height: 1px;
}

.border1px {
	border: 1px solid black;
}

.padding-right {
	padding-right: 10px;
}

.online {
	text-shadow: rgb(180, 180, 180) 4px 4px 1px, rgb(171, 168, 168) 5px 13px 5px;
	cursor: pointer;
}

.classBox {
	border: 1px solid black;
	border-radius: 10px;
	background-color: #D8E7F4;
	width: 220px;
	height: 220px;
	padding: 25px;
	text-align: center;
	font-weight: bold;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;
}

.docBox {
	border: 1px solid black;
	border-radius: 10px;
	background-color: #D8E7F4;
	width: 150px;
	min-width: 150px;
	max-width: 150px;
	height: 150px;
	padding: 15px;
	text-align: center;
	font-weight: bold;
	cursor: pointer;
	margin: 5px;
	vertical-align: middle;
	flex-flow: row;
}

.videoFiles {
	width: 100%;
	display: flex;
}

.videoDates {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.react-player {
	margin: 5px;
}

.react-thumbnail-generator {
	width: 150px;
	height: 120px;
}

.dateStyle {
	margin-right: 10px;
	text-decoration: underline;
	display: table-cell;
}

input[type="text"], input[type="password"] {
	margin-bottom: 10px !important;
}

select {
	margin-bottom: 10px !important;
}

.react-contextmenu {
	background-color: darkgrey;
	width: 200px;
	height: 120px;
}

.react-contextmenu-item {
	padding-left: 10px;
	cursor: pointer;
}

.linkStyle {
	text-decoration: underline;
    cursor: pointer;
    font-size: 24px;
}

.red {
    color: red;
}

.lesson {
	font-size: 18px;
}

@media (min-width: 1200px) {
	.row:after, .row:before {
		display: table;
		line-height: 0;
		content: "";
	}
}

.navbar-collapse {
	margin: -8px;
}

.navbar-brand {
	height: 60px;
}

.dropdown-menu {
    margin-top: 0;
}
.dropdown-menu .dropdown-toggle::after {
    vertical-align: middle;
    border-left: 4px solid;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
}
.dropdown-menu .dropdown .dropdown-menu {
    left: 100%;
    top: 0%;
    margin:0 20px;
    border-width: 0;
}
.dropdown-menu .dropdown .dropdown-menu.left {
    right: 100%;
    left: auto;
}

.dropdown-menu > li a:hover,
.dropdown-menu > li.show {
    background: #007bff;
    color: white;
}
.dropdown-menu > li.show > a{
    color: white;
}

@media (min-width: 768px) {
    .dropdown-menu .dropdown .dropdown-menu {
        margin:0;
        border-width: 1px;
    }
}

.nav-item {
	list-style: none;
}

.nav-item a {
	color: #1B3E70;
	text-decoration: none;
}

.nav-item a:hover {
	background-color: #60c0f3;
}

.nav-item .active {
	background-color: #60c0f3;
}

.ad-panel {
	background-color: #dfe7ee;
	padding: 5px;
	font-weight: bold;
	font-size: 16px;
	color: #ffffff;
	text-align: right;
}

.header-panel {
	background-color: #155173;
	font-weight: bold;
	font-size: 30px;
	color: #ffffff;
	text-align: center;
	padding: 20px;
	width: 1140px;
}

.video-panel {
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.video-player {
	max-width: 1140px;
}

.method-name {
	border: 2px solid black;
	border-radius: 8px;
}

#testResultTable {
	border: 1px solid black;
	width: 100%;
}

table#testResultTable tr td {
	border: 1px solid black;
	text-align: center;
}