body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select {
    display: block !important;
    margin: 0 0 5px 0;
}

.swal2-popup .swal2-content select {
    display: none !important;
}

nav {
    color: #fff;
    background-color: #fbfbfb;
    width: 100%;
    height: 56px;
    line-height: 56px;
}

nav ul a {
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    font-size: 1rem;
    color: #fff;
    display: block;
    padding: 0 15px;
    cursor: pointer;
}

ul:not(.browser-default) > li {
    list-style-type: none;
}

nav ul li {
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    float: left;
    padding: 0;
}

ul:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;
}

.right {
    float: right !important;
}

nav ul {
    margin: 0;
}

ul {
    float: left;
}

.nav-wrapper div {
    border-left: 1px solid #000000;
    float: left;
    width: 140px;
}

.nav-wrapper div:last-child {
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    float: left;
    width: 120px;
}

.nav-wrapper div:hover {
    background-color: #e6e6e6;
}

#addPsychTable > tr > td {
    padding: 15px 0;
}